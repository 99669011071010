// /* global BigInt */
// const { Web3 } = require("web3");
// const { ethers } = require("ethers");
// const CryptoJS = require("crypto-js");
// const { SmartContractABI } = require("../service/abi/new-abi");
// const { default: notify } = require("../utils/notify");

// const web3 = new Web3(window.ethereum);
// // const eth_provider = new ethers.BrowserProvider(window.ethereum);
// function activateInjectedProvider(providerName) {
//   const { ethereum } = window;

//   if (!ethereum?.providers) {
//     return undefined;
//   }

//   let provider;
//   switch (providerName) {
//     case "MetaMask":
//       provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
//       break;
//   }

//   if (provider) {
//     ethereum.setSelectedProvider(provider);
//   }
// }
// const _isMetamaskInstalled = () => {
//   return new Promise((resolve, reject) => {
//     if (
//       Web3.givenProvider !== null &&
//       typeof window.ethereum !== "undefined" &&
//       window.ethereum.isMetaMask
//     ) {
//       resolve(true);
//     }
//     reject(false);
//   });
// };
// const _connectToMetamask = async () => {
//   activateInjectedProvider("MetaMask");
//   const isMetamaskInstalled = await _isMetamaskInstalled();
//   if (!isMetamaskInstalled) {
//     return { error: true, message: "Metamask in not installed!" };
//   }
//   if (window.ethereum) {
//     const accounts = await window.ethereum.request({
//       method: "eth_requestAccounts",
//     });
//     return { error: false, accountAddress: accounts[0] };
//   }
// };
// const _disconnectToMetamask = async () => {
//   const isMetamaskInstalled = await _isMetamaskInstalled();
//   if (!isMetamaskInstalled) {
//     return { error: true, message: "Metamask in not installed!" };
//   }
//   if (window.ethereum) {
//     // window.ethereum.removeAllListeners(); // Remove all listeners added by MetaMask
//     // window.web3.eth.currentProvider.disconnect();
//     // window.ethereum.disconnect();
//     return { error: false, message: "Wallet Disconnected" };
//   }
// };
// const _getActiveAccount = async () => {
//   const accounts = await web3.eth.requestAccounts();

//   if (accounts.length > 0) {
//     return { error: false, account: accounts[0] };
//   }

//   return { error: true, message: "No active account is found!" };
// };
// // const _tranferToken = async (data) => {
// //   await _connectToMetamask();
// //   const accountAddress = await _getActiveAccount();
// //   if (accountAddress.error) {
// //     return false;
// //   }
// //   const stFactory = new web3.eth.Contract(
// //     SmartContractABI,
// //     "0x534bD102153EF199abAe8296a2FaE4599fC44Cdc"
// //   );
// //   console.log("accountAddress", accountAddress);
// //   const tx = await stFactory.methods
// //     .transfer("0x24bdcfF4C7943512d1f351116ECE0304903203C4", data * 10 ** 6)
// //     .send({ from: accountAddress.account });
// //   return tx;
// // };
// const checkMetaMask = async (userWallet = "") => {
//   activateInjectedProvider("MetaMask");
//   if (window.ethereum && window.ethereum.isMetaMask) {
//     const provider = new ethers.BrowserProvider(window.ethereum);
//     try {
//       const accounts = await provider.send("eth_requestAccounts", []);
//       if (userWallet !== "") {
//         if (accounts[0] !== userWallet) {
//           notify.error(
//             "Wallet connect to the website is different from User Profile wallet "
//           );
//           return { connected: false };
//         }
//       }
//       return { connected: true };
//     } catch (error) {
//       notify.error("Error connecting to Metamask:");
//       return { connected: false };
//     }
//   } else {
//     notify.error("Metamask not detected.");
//     return { connected: false };
//   }
// };
// const _getCurrentNetwork = async () => {
//   try {
//     if (typeof window.ethereum !== undefined && window.ethereum.isMetaMask) {
//       // now we will get the current network
//       const currentNetwork = await window.ethereum.request({
//         method: "net_version",
//       });
//       if (currentNetwork != process.env.REACT_APP_CHAIN_ID) {
//         notify.error("Network not exist");
//         await _changeNetwork();
//       }
//     } else {
//       notify.error("Metamask not installed!");
//     }
//   } catch (err) {
//     console.log("getCurrentNetwork", err);
//   }
// };
// const _changeNetwork = async () => {
//   const chainId = parseFloat(process.env.REACT_APP_CHAIN_ID);
//   const formattedChainID = `0x${chainId.toString(16)}`;
//   console.log("formattedChainID", formattedChainID, typeof chainId);
//   const currentNetwork = await window.ethereum.request({
//     method: "net_version",
//   });
//   if (currentNetwork != chainId) {
//     try {
//       await window.ethereum.request({
//         method: "wallet_switchEthereumChain",
//         params: [{ chainId: formattedChainID }],
//       });
//     } catch (err) {
//       // This error code indicates that the chain has not been added to MetaMask
//       if (err.code === 4902) {
//         try {
//           await window.ethereum.request({
//             method: "wallet_addEthereumChain",
//             params: [
//               {
//                 chainName: process.env.REACT_APP_NETWORK_NAME,
//                 chainId: formattedChainID,
//                 nativeCurrency: {
//                   name: process.env.REACT_APP_CURRENCY_SYMBOL,
//                   decimals: 18,
//                   symbol: process.env.REACT_APP_CURRENCY_SYMBOL,
//                 },
//                 rpcUrls: [process.env.REACT_APP_RPC_URL],
//               },
//             ],
//           });
//         } catch (error) {
//           console.log("ERROR", error);
//           handleTransactionError(error);
//         }
//       }
//     }
//   }
// };

// const _tranferToken = async (
//   data,
//   contractAddress,
//   adminWallet,
//   userWallet
// ) => {
//   try {
//     // console.log(data, contractAddress, adminWallet);
//     const check = await checkMetaMask(userWallet);
//     if (!check.connected) return;
//     await _getCurrentNetwork();

//     const eth_provider = new ethers.BrowserProvider(window.ethereum);
//     const signer = await eth_provider.getSigner();

//     const MyContract = new ethers.Contract(
//       contractAddress,
//       SmartContractABI,
//       signer
//     );

//     const amount = BigInt(data * 10 ** 6);
//     const tx = await MyContract.transfer(adminWallet, amount);
//     return tx;
//   } catch (err) {
//     return handleTransactionError(err);
//   }
// };
// const handleTransactionError = (error) => {
//   console.log(error.code, error);
//   if (error.code === "ACTION_REJECTED" || error.code == 4001) {
//     notify.error("User rejected the transaction.");
//     return error.code;
//   } else if (error.code === "CALL_EXCEPTION") {
//     notify.error("User does not have enough tokens.");
//     return error.code;
//   }
// };
// const _generateWalletAddress = (username) => {
//   const encodedData = username;
//   const hashedData = CryptoJS.SHA256(encodedData).toString(CryptoJS.enc.Hex);
//   const addressBytes = "0x" + hashedData.slice(0, 40); // Taking the first 20 bytes (40 hexadecimal characters)

//   const treasuryAddress = addressBytes;
//   return treasuryAddress;
// };

// const _userSign = async (amount, date, order, distributor) => {
//   try {
//     const check = await checkMetaMask();
//     if (!check.connected) return;
//     await _getCurrentNetwork();

//     const eth_provider = new ethers.BrowserProvider(window.ethereum);
//     const signer = await eth_provider.getSigner();

//     const message = `This is to confirm that amount of ${amount} is received at date ${date} and is submitted by Distributor ${order.distributor} for Sale Order ${order.order_ref} and User ${order.consumer}`;
//     const signature = await signer.signMessage(message);
//     return signature;
//   } catch (err) {
//     return handleTransactionError(err);
//   }
// };
// // const _genosisSignTransaction = async () => {
// //   let provider;
// //   if (window.ethereum == null) {
// //     console.log("MetaMask not installed; using read-only defaults");
// //     provider = ethers.getDefaultProvider();
// //   } else {
// //     provider = new ethers.providers.Web3Provider(window.ethereum);
// //   }

// //   const signer = provider.getSigner();
// //   const chainId = process.env.REACT_APP_CHAIN_ID;
// //   const apiKit = new SafeApiKit({
// //     chainId: ethers.BigNumber.from(chainId).toNumber(),
// //     provider,
// //   });

// //   const ethAdapter = new EthersAdapter({
// //     ethers,
// //     signerOrProvider: signer,
// //   });

// //   // Create Safe instance
// //   const protocolKit = await Safe.create({
// //     ethAdapter,
// //     safeAddress: "0xA79350fC9b1442374c00E665b2Ff275Dae569e79",
// //   });

// //   // Create transaction
// //   const safeTransactionData = {
// //     to: "0xa01A4d52dC51Ed4018FEB68369030deF544ea69e",
// //     value: ethers.utils.parseUnits("1", "wei").toString(), // 1 wei
// //     data: "0x",
// //   };

// //   const safeTransaction = await protocolKit.createTransaction({
// //     safeTransactionData: [safeTransactionData],
// //   });

// //   const senderAddress = await signer.getAddress();
// //   const safeTxHash = await protocolKit.getTransactionHash(safeTransaction);
// //   const signature = await protocolKit.signTransactionHash(safeTxHash);

// //   // Propose transaction to the service
// //   const hash = await apiKit.proposeTransaction({
// //     safeAddress: await protocolKit.getAddress(),
// //     safeTransactionData: safeTransaction.data,
// //     safeTxHash,
// //     senderAddress,
// //     senderSignature: signature.data,
// //   });

// //   console.log("Hash", hash);
// //   return hash;
// // };

// module.exports = {
//   _isMetamaskInstalled,
//   _connectToMetamask,
//   _disconnectToMetamask,
//   _getActiveAccount,
//   _tranferToken,
//   _generateWalletAddress,
//   _userSign,
//   // _genosisSignTransaction,
// };

/* global BigInt */
const { Web3 } = require("web3");
const { ethers } = require("ethers");
const CryptoJS = require("crypto-js");
const { SmartContractABI } = require("../service/abi/new-abi");
const { MetaTransactionABI } = require("../service/abi/new-usdt-abi.json");
const { SecondSmartContract } = require("../service/abi/second-smart-contract");
const { default: notify } = require("../utils/notify");
const { convertToUint256 } = require("../utils/common");

const web3 = new Web3(window.ethereum);

function activateInjectedProvider(providerName) {
  const { ethereum } = window;

  if (!ethereum?.providers) {
    return undefined;
  }

  let provider;
  switch (providerName) {
    case "MetaMask":
      provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
      break;
  }

  if (provider) {
    ethereum.setSelectedProvider(provider);
  }
}
const toBytes32 = (str) => {
  if (str.length > 32) {
    throw new Error("String is too long to convert to bytes32");
  }

  let hexStr = "0x";
  for (let i = 0; i < str.length; i++) {
    const hex = str.charCodeAt(i).toString(16);
    hexStr += hex.length === 1 ? "0" + hex : hex;
  }

  while (hexStr.length < 66) {
    hexStr += "0";
  }

  return hexStr;
};

const _isMetamaskInstalled = () => {
  return new Promise((resolve, reject) => {
    if (
      Web3.givenProvider !== null &&
      typeof window.ethereum !== "undefined" &&
      window.ethereum.isMetaMask
    ) {
      resolve(true);
    }
    reject(false);
  });
};

const _connectToMetamask = async () => {
  activateInjectedProvider("MetaMask");
  console.log("REACHED", await _isMetamaskInstalled());
  const isMetamaskInstalled = await _isMetamaskInstalled();
  console.log("isMetamaskInstalled", isMetamaskInstalled);
  if (!isMetamaskInstalled) {
    return { error: true, message: "MetaMask is not installed!" };
  }
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    return { error: false, accountAddress: accounts[0] };
  }
};

const _disconnectToMetamask = async () => {
  const isMetamaskInstalled = await _isMetamaskInstalled();
  if (!isMetamaskInstalled) {
    return { error: true, message: "MetaMask is not installed!" };
  }
  if (window.ethereum) {
    return { error: false, message: "Wallet Disconnected" };
  }
};

const _getActiveAccount = async () => {
  const accounts = await web3.eth.requestAccounts();

  if (accounts.length > 0) {
    return { error: false, account: accounts[0] };
  }

  return { error: true, message: "No active account found!" };
};

const checkMetaMask = async (userWallet = "") => {
  activateInjectedProvider("MetaMask");

  if (window.ethereum && window.ethereum.isMetaMask) {
    const provider = new ethers.BrowserProvider(window.ethereum);
    try {
      const accounts = await provider.send("eth_requestAccounts", []);
      if (userWallet !== "" && accounts[0] !== userWallet.toLocaleLowerCase()) {
        notify.error("Connected wallet is different from user profile wallet.");
        return { connected: false };
      }
      return { connected: true };
    } catch (error) {
      notify.error("Error connecting to MetaMask.");
      return { connected: false };
    }
  } else {
    notify.error("MetaMask not detected.");
    return { connected: false };
  }
};

const _getCurrentNetwork = async (str = "") => {
  try {
    if (typeof window.ethereum !== "undefined" && window.ethereum.isMetaMask) {
      const currentNetwork = await window.ethereum.request({
        method: "net_version",
      });
      if (str === "") {
        if (currentNetwork != process.env.REACT_APP_CHAIN_ID) {
          notify.success("Changing network.");
          await _changeNetwork(
            process.env.REACT_APP_CHAIN_ID,
            process.env.REACT_APP_NETWORK_NAME,
            process.env.REACT_APP_CURRENCY_SYMBOL,
            process.env.REACT_APP_RPC_URL,
            process.env.REACT_APP_BLOCK_EXPLORER_URLS
          );
        }
      } else {
        if (currentNetwork != process.env.REACT_APP_CHAIN_ID_MATIC) {
          notify.success("Changing network.");
          await _changeNetwork(
            process.env.REACT_APP_CHAIN_ID_MATIC,
            process.env.REACT_APP_NETWORK_NAME_MATIC,
            process.env.REACT_APP_CURRENCY_SYMBOL_MATIC,
            process.env.REACT_APP_RPC_URL_MATIC,
            process.env.REACT_APP_BLOCK_EXPLORER_URLS_MATIC
          );
        }
      }
    } else {
      notify.error("MetaMask not installed!");
    }
  } catch (err) {
    console.log("getCurrentNetwork", err);
  }
};

const _changeNetwork = async (
  CHAIN_ID,
  NETWORK_NAME,
  CURRENCY_SYMBOL,
  RPC_URL,
  EXPLORER_URLS
) => {
  const chainId = parseFloat(CHAIN_ID);
  const formattedChainID = `0x${chainId.toString(16)}`;
  const currentNetwork = await window.ethereum.request({
    method: "net_version",
  });
  if (currentNetwork != chainId) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: formattedChainID }],
      });
    } catch (err) {
      console.log("ERR", err);
      console.log("CHECK", NETWORK_NAME, chainId, CURRENCY_SYMBOL, RPC_URL);
      if (err.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: NETWORK_NAME,
                chainId: formattedChainID,
                nativeCurrency: {
                  name: CURRENCY_SYMBOL,
                  decimals: 18,
                  symbol: CURRENCY_SYMBOL,
                },
                rpcUrls: [RPC_URL],
                blockExplorerUrls: [EXPLORER_URLS],
              },
            ],
          });
        } catch (error) {
          console.log("ERROR", error);
          handleTransactionError(error);
        }
      }
    }
  }
};

const _createRedemption = async (
  contractAddress,
  redemptionID,
  amount,
  nonRefendableFee
) => {
  try {
    activateInjectedProvider("MetaMask");

    const check = await checkMetaMask("");
    if (!check.connected) return;
    await _getCurrentNetwork("polygon");

    const eth_provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await eth_provider.getSigner();
    const MyRedeemContract = new ethers.Contract(
      contractAddress,
      SecondSmartContract,
      signer
    );
    const tx = await MyRedeemContract.createRedemptionRequest(
      toBytes32(redemptionID),
      parseInt(amount * 100)
    );
    return tx;
  } catch (err) {
    return handleTransactionError(err);
  }
};
const _processRedeemRequest = async (contractAddress, redemptionID) => {
  try {
    activateInjectedProvider("MetaMask");

    const check = await checkMetaMask("");
    if (!check.connected) return;
    await _getCurrentNetwork("polygon");

    const eth_provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await eth_provider.getSigner();

    const MyRedeemContract = new ethers.Contract(
      contractAddress,
      SecondSmartContract,
      signer
    );
    const tx = await MyRedeemContract.processRedemptionRequest(
      toBytes32(redemptionID)
    );
    return tx;
  } catch (err) {
    return handleTransactionError(err);
  }
};
const _tranferToken = async (
  data,
  contractAddress,
  adminWallet,
  userWallet
) => {
  try {
    activateInjectedProvider("MetaMask");

    const check = await checkMetaMask(userWallet);
    if (!check.connected) return;
    await _getCurrentNetwork();

    const eth_provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await eth_provider.getSigner();

    const MyContract = new ethers.Contract(
      contractAddress,
      SmartContractABI,
      signer
    );

    const amount = BigInt(data * 10 ** 6);

    const tx = await MyContract.transfer(adminWallet, amount);
    return tx;
  } catch (err) {
    return handleTransactionError(err);
  }
};
const _directTranferToken = async (
  data,
  contractAddress,
  adminWallet,
  userWallet
) => {
  try {
    activateInjectedProvider("MetaMask");

    const check = await checkMetaMask(userWallet);
    if (!check.connected) return;
    await _getCurrentNetwork("polygon");

    const eth_provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await eth_provider.getSigner();
    console.log("contract", adminWallet, data);

    const MyContract = new ethers.Contract(
      contractAddress,
      MetaTransactionABI,
      signer
    );

    const amount = BigInt(data * 10 ** 2);
    const tx = await MyContract.transfer(adminWallet, amount);
    return tx;
  } catch (err) {
    return handleTransactionError(err);
  }
};
const _signMessage = async (message) => {
  try {
    activateInjectedProvider("MetaMask");

    const check = await checkMetaMask("");
    if (!check.connected) return;

    const eth_provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await eth_provider.getSigner();
    const signature = await signer.signMessage(message);
    return signature;
  } catch (err) {
    return handleTransactionError(err);
  }
};
const _createRedemptionSign = async (messages, wallet) => {
  try {
    activateInjectedProvider("MetaMask");

    const check = await checkMetaMask(wallet);
    if (!check.connected) return;
    await _getCurrentNetwork("polygon");
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const signature = await signer.signTypedData(
      messages.domain,
      messages.types,
      messages.message
    );
    // const recoveredAddress = ethers.verifyTypedData(
    //   data.domain,
    //   data.types,
    //   data.message,
    //   signature
    // );
    // console.log("Recovered Address:", recoveredAddress);

    console.log("signature", signature);
    return signature;
  } catch (err) {
    return handleTransactionError(err);
  }
};

const handleTransactionError = (error) => {
  console.log("handleTransactionError", error.code, error);
  if (error.code === "ACTION_REJECTED" || error.code == 4001) {
    notify.error("User rejected the transaction.");
    return error.code;
  } else if (error.code === "CALL_EXCEPTION") {
    notify.error("User does not have enough tokens.");
    return error.code;
  }
};

const _generateWalletAddress = (username) => {
  const encodedData = username;
  const hashedData = CryptoJS.SHA256(encodedData).toString(CryptoJS.enc.Hex);
  const addressBytes = "0x" + hashedData.slice(0, 40);
  const treasuryAddress = addressBytes;
  return treasuryAddress;
};

const _userSign = async (amount, date, order, distributor) => {
  try {
    const check = await checkMetaMask();
    if (!check.connected) return;
    await _getCurrentNetwork();

    const eth_provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await eth_provider.getSigner();

    const message = `This is to confirm that amount of ${amount} is received at date ${date} and is submitted by Distributor ${order.distributor} for Sale Order ${order.order_ref} and User ${order.consumer}`;
    const signature = await signer.signMessage(message);
    return signature;
  } catch (err) {
    return handleTransactionError(err);
  }
};

module.exports = {
  _isMetamaskInstalled,
  _connectToMetamask,
  _disconnectToMetamask,
  _getActiveAccount,
  _tranferToken,
  _generateWalletAddress,
  _userSign,
  _createRedemption,
  _processRedeemRequest,
  _signMessage,
  _createRedemptionSign,

  _directTranferToken,
};
